<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">MEKONG-LANCANG</h4>
            </div>
            <div class="card-body pb-1">
              <!-- <p class="card-text">....</p> -->
              <p class="card-text">
                Education plays a vital role in shaping the minds of
                individuals, equipping them with the knowledge, skills, and
                values necessary to succeed in life. A well-rounded education
                provides students with a strong foundation in core subjects such
                as mathematics, science, and language arts, while also offering
                opportunities to explore their interests and passions through
                extracurricular activities and electives. Effective education
                can help individuals develop critical thinking, problem-solving,
                and communication skills, which are essential for personal and
                professional growth. Moreover, education has the power to break
                down social and economic barriers, promote social mobility, and
                foster a more just and equitable society..
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
