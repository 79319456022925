import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  NavigationStart,
} from "@angular/router";

import { AuthenticationService, LocalService } from "app/auth/service";
import { ToastService } from "../service/toast.service";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  public permission = [];
  private token: string;
  private url: string;
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private toastService: ToastService,
    private _localService: LocalService
  ) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.url = event.url;
        // this._router.navigate(["/pages/authentication/login"]);
      });
  }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot) {
    try {
      this.permission = JSON.parse(this._localService.getData("_r"));

      this.token = this._localService.getData("_t");
      
      if (this.token && this.permission.length) {
        // check if route is restricted by role
        // if (
        //   !this.permission[route.data.feature.toLowerCase()][route.data.action]
        // ) {
        //   this.showToaster("Access denied", "Please login to continue access");

        //   this._router.navigate(["/pages/miscellaneous/not-authorized"]);
        //   return false;
        // }
        if (
          this.permission.some(
            (i) => i == route.data.permission
          )
        ) return true;
      }
      // return false;
    } catch (error) {
      this._router.navigate(["/pages/authentication/login"]);
      return false;
    }
  }

  showToaster(title: string, message: string) {
    this.toastService.show(message, {
      classname: "bg-danger text-light",
      delay: 2000,
      autohide: true,
      headertext: title,
    });
  }
}
