import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginateComponent } from "./paginate.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [PaginateComponent],
  imports: [CommonModule, NgbPaginationModule, FormsModule],
  exports: [PaginateComponent],
})
export class PaginateModule {}
