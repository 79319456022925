import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import {
  CoreSidebarModule,
  CoreSpinnerModule,
  CoreThemeCustomizerModule,
} from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { FeatherModule } from "angular-feather";
import { allIcons } from "angular-feather/icons";
import { FormsModule } from "@angular/forms";
import {
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";

import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { MatDialogContent, MatDialogModule } from "@angular/material/dialog";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";

const appRoutes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "",
    redirectTo: "/home",
    data: { role: "Manager" },
    pathMatch: "full",
  },
  {
    path: "setting-management",
    loadChildren: () =>
      import("./main/pages/setting-management/setting-management.module").then(
        (m) => m.SettingManagementModule
      ),
  },
  // {
  //   path: "customer-management",
  //   loadChildren: () =>
  //     import(
  //       "./main/pages/customer-management/customer-management.module"
  //     ).then((m) => m.CustomerManagementModule),
  // },

  // {
  //   path: "**",
  //   pathMatch: "full",
  //   redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  // },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CorePipesModule,
    MatDialogModule,
    NgImageFullscreenViewModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    BrowserModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
      useHash: true,
    }),
    // RouterModule.forRoot(appRoutes, { useHash: false }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    FeatherModule.pick(allIcons),
    CoreSpinnerModule,
    // App modules
    LayoutModule,
    SampleModule,
  ],
  // { provide: LocationStrategy, useClass: HashLocationStrategy }
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
