<form [formGroup]="form">
  <div class="form-group">
    <label for="name_column"
      >{{ label }}
      <span *ngIf="isRequired == true" class="required-input">*</span></label
    >
    <!-- Text -->
    <ng-container>
      <input
        *ngIf="type == 'text'"
        [formControlName]="controlName"
        type="text"
        class="form-control input-md"
        [placeholder]="placeHolder"
        [required]="isRequired"
        [disabled]="isdisabled"
        [class.error]="form.controls[controlName].invalid && ngForm.submitted"
      />
    </ng-container>

    <!-- Password -->
    <ng-container>
      <input
        *ngIf="type == 'password'"
        [formControlName]="controlName"
        type="password"
        class="form-control input-md"
        [placeholder]="placeHolder"
        [required]="isRequired"
        [disabled]="isdisabled"
        [class.error]="form.controls[controlName].invalid && ngForm.submitted"
      />
    </ng-container>

    <!-- Text Area -->
    <ng-container *ngIf="type == 'textArea'">
      <textarea
        rows="10"
        [formControlName]="controlName"
        type="text"
        class="form-control input-md"
        placeholder="content"
        [required]="isRequired"
        [class.error]="form.controls[controlName].invalid && ngForm.submitted"
      ></textarea>
    </ng-container>

    <!-- Select -->
    <ng-container *ngIf="type == 'select'">
      <select
        [formControlName]="controlName"
        class="form-control"
        id="basicSelect"
      >
        <option [value]="null" selected>{{ placeHolder }}</option>
        <option *ngFor="let item of values" [value]="item[outComeField]">
          {{ item?.name }}
        </option>
      </select>
    </ng-container>

    <!-- Select Search -->
    <ng-container *ngIf="type == 'select-search'">
      <ng-select
        class="form-select"
        [items]="values"
        bindLabel="name"
        [formControlName]="controlName"
        bindValue="id"
      >
      </ng-select>
    </ng-container>

    <!-- Date Time -->
    <ng-container *ngIf="type == 'dateTime'">
      <input
        [owlDateTimeTrigger]="startDate"
        class="form-control input-md"
        [owlDateTime]="startDate"
        placeholder="Date Time"
        [formControlName]="controlName"
        type="text"
        [class.error]="form.controls[controlName].invalid && ngForm.submitted"
      />
      <owl-date-time #startDate></owl-date-time>
    </ng-container>

    <!-- TextEditor -->
    <ng-container *ngIf="type == 'textEditor'">
      <div class="form-group">
        <quill-editor
          [modules]="module"
          [class.error]="form.controls[controlName].invalid && ngForm.submitted"
          [formControlName]="controlName"
        >
        </quill-editor>
      </div>
    </ng-container>

    <span
      *ngIf="
        (form.controls[controlName].hasError('required') &&
          form.controls[controlName].touched) ||
        (form.controls[controlName].hasError('required') && ngForm.submitted)
      "
      class="invalid-form"
    >
      <small class="form-text text-danger danger"
        >This field is required!</small
      >
    </span>
    <span
      *ngIf="
        (form.controls[controlName].hasError('invalid') &&
          form.controls[controlName].touched) ||
        (form.controls[controlName].hasError('invalid') && ngForm.submitted)
      "
      class="invalid-form"
    >
      <small class="form-text text-danger danger">{{
        form.controls[controlName].getError("invalid")
      }}</small>
    </span>
  </div>
</form>
