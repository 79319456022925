export const locale = {
  lang: "en",
  data: {
    MENU: {
      HOME: "Home",
      POST: {
        POST_MANAGEMENT: "Post",
        POST_LIST: "List",
        CATEGORY: "Category",
      },
      DESTINATION: {
        DESTINATION_MANAGEMENT: "Destination",
        DESTINATION_LIST: "Destination",
        CATEGORY: "Category",
        PLACE_TO_GO: "Place To Go",
      },
      COURSE: {
        COURSE_MANAGEMENT: "Course",
        COURSE_LIST: "Course",
        CATEGORY: "Category",
      },
      YOUTH_AMBASSADOR: {
        YOUTH_AMBASSADOR_MANAGEMENT: "Youth Ambassador",
        ACTIVITY: "Activity",
        PROGRAM: "Program",
      },
      EVENT: {
        EVENT_MANAGEMENT: "Event",
        EVENT_LIST: "Event",
      },
      CUSTOMER: {
        CUSTOMER_MANAGEMENT: "Customers",
      },
      SETTING: {
        SETTING: "Setting",
        ABOUT: "About",
        WHAT_WE_DO: "What We Do",
        GUIDELINES_ON_SELF_DRIVE: "Guidelines on self drive",
      },
      E_LIBRARY: {
        E_LIBRARY_MANAGEMENT: "E-Library",
        E_LIBRARY: "E-Library",
        TOP_ELIBRARY_LIST: "Top E-Library",
        E_LIBRARY_CATEGORY: "E-Library Category",
      },
      ADMINISTRATOR: {
        ADMIN_ADMINISTRATOR: "Administrator",
        ADMIN: "Admin",
        ROLE: "Role",
      },
    },
  },
};
