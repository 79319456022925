import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "status",
})
export class StatusPipe implements PipeTransform {
  transform(status: boolean, opposite: boolean): string {
    switch (status) {
      case true:
        if (opposite) return "InActive";
        return "Active";
      default:
        if (opposite) return "Active";
        return "InActive";
    }
  }
}
