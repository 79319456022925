import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreInputComponent } from "./core-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { NgSelectModule } from "@ng-select/ng-select";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [CoreInputComponent],
  exports: [CoreInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    QuillModule.forRoot(),
  ],
})
export class CoreInputModule {}
