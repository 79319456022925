import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { CustomBreakPointsProvider } from "app/layout/custom-breakpoints";
import { VerticalLayoutModule } from "app/layout/vertical/vertical-layout.module";
import { HorizontalLayoutModule } from "app/layout/horizontal/horizontal-layout.module";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { FeatherModule } from "angular-feather";
import { CommonModule } from "@angular/common";
import { CorePipesModule } from "../../@core/pipes/pipes.module";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  providers: [CustomBreakPointsProvider],
  exports: [VerticalLayoutModule, HorizontalLayoutModule],
  declarations: [ConfirmDialogComponent],
  imports: [
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    FeatherModule,
    VerticalLayoutModule,
    HorizontalLayoutModule,
    CommonModule,
    CorePipesModule,
    ToastrModule.forRoot(),
  ],
})
export class LayoutModule {}
