import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-core-spinner",
  templateUrl: "./core-spinner.component.html",
  styleUrls: ["./core-spinner.component.scss"],
})
export class CoreSpinnerComponent implements OnInit {
  @Input() store;
  constructor() {}

  ngOnInit() {}
}
