import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-core-input",
  templateUrl: "./core-input.component.html",
  styleUrls: ["./core-input.component.scss"],
})
export class CoreInputComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() ngForm;
  @Input() controlName: string;
  @Input() label: string;
  @Input() type: string;
  @Input() placeHolder: string = "";
  @Input() isRequired: boolean;
  @Input() values: Array<Object>;
  @Input() outComeField: string;
  @Input() isdisabled: boolean;
  @Input() module: any;

  constructor() {}

  ngOnInit(): void {}
}
