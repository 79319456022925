<div class="dialog">
  <div class="dialog-header">
    <h2>{{ data.title | titleCaseWord }}</h2>
    <!-- <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="dialog-content">
    <p>{{ data.description | titleCaseWord }}</p>
  </div>
  <div class="dialog-actions">
    <!-- <button class="btn btn-outline-primary waves-effect">No</button> -->
    <ng-container *ngFor="let item of data?.btn">
      <button
        (click)="_closeDailog(item.value)"
        type="button"
        class="btn btn-outline-primary"
        [class]="item.class"
        rippleEffect
      >
        <!-- <ng-container *ngIf="item.icon"> -->
        <i-feather
          *ngIf="item.icon"
          style="width: 14px; height: 14px"
          [name]="item.icon"
        ></i-feather>
        <!-- </ng-container> -->

        {{ item.name | titleCaseWord }}
      </button>
    </ng-container>
    <!-- <button
      (click)="_closeDailog(1)"
      type="button"
      class="btn btn-primary btn-delete"
      rippleEffect
    >
      <i-feather style="width: 14px; height: 14px" name="trash"></i-feather>
      Delete
    </button> -->
  </div>
</div>
