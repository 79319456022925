import { NgModule } from "@angular/core";
import { FilterPipe } from "@core/pipes/filter.pipe";
import { InitialsPipe } from "@core/pipes/initials.pipe";
import { SafePipe } from "@core/pipes/safe.pipe";
import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";
import { StatusPipe } from "./status.pipe";
import { DateTimeAgoPipe } from "./date-time-ago.pipe";
import { TitleCaseWordPipe } from "./title-case-word.pipe";
import { MimeTypePipe } from "./mime-type.pipe";

@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    StatusPipe,
    DateTimeAgoPipe,
    TitleCaseWordPipe,
    MimeTypePipe
  ],
  imports: [],
  exports: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    StatusPipe,
    DateTimeAgoPipe,
    TitleCaseWordPipe,
    MimeTypePipe
  ],
})
export class CorePipesModule {}
