import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
  },
  {
    id: "post",
    title: "Posts",
    translate: "MENU.POST.POST_MANAGEMENT",
    type: "collapsible",
    icon: "package",
    children: [
      {
        id: "post",
        title: "List",
        translate: "MENU.POST.POST_LIST",
        type: "item",
        icon: "circle",
        url: "pages/post-management/post",
      },
      {
        id: "post-tag",
        title: "Tags",
        translate: "MENU.POST.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/post-management/post-tag",
      },
    ],
  },
  {
    id: "destination",
    title: "Destinations",
    translate: "MENU.DESTINATION.DESTINATION_MANAGEMENT",
    type: "collapsible",
    icon: "map",
    children: [
      {
        id: "destination",
        title: "List",
        translate: "MENU.DESTINATION.DESTINATION_LIST",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/destination",
      },
      {
        id: "destination-category",
        title: "Place To Go",
        translate: "MENU.DESTINATION.PLACE_TO_GO",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/place-to-go",
      },
      {
        id: "destination-category",
        title: "Categories",
        translate: "MENU.DESTINATION.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/destination-category",
      },
    ],
  },
  {
    id: "course",
    title: "Courses",
    translate: "MENU.COURSE.COURSE_MANAGEMENT",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "course",
        title: "List",
        translate: "MENU.COURSE.COURSE_LIST",
        type: "item",
        icon: "circle",
        url: "pages/course-management/course",
      },
      {
        id: "course-category",
        title: "Categories",
        translate: "MENU.COURSE.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/course-management/course-category",
      },
    ],
  },
  {
    id: "youth-ambassador",
    title: "Youth Ambassador",
    translate: "MENU.YOUTH_AMBASSADOR.YOUTH_AMBASSADOR_MANAGEMENT",
    type: "collapsible",
    icon: "award",
    children: [
      {
        id: "youth-ambassador-activity",
        title: "Activities",
        translate: "MENU.YOUTH_AMBASSADOR.ACTIVITY",
        type: "item",
        icon: "circle",
        url: "pages/youth-ambassador-management/activity",
      },
      {
        id: "youth-ambassador-program",
        title: "Programs",
        translate: "MENU.YOUTH_AMBASSADOR.PROGRAM",
        type: "item",
        icon: "circle",
        url: "pages/youth-ambassador-management/program",
      },
    ],
  },
  {
    id: "event",
    title: "Events",
    translate: "MENU.EVENT.EVENT_MANAGEMENT",
    type: "item",
    icon: "cast",
    url: "pages/event-management/event",
  },
  {
    id: "customer",
    title: "Customers",
    translate: "MENU.CUSTOMER.CUSTOMER_MANAGEMENT",
    type: "item",
    icon: "user",
    url: "pages/customer-management/customer",
  },
  {
    id: "admin",
    title: "Administrator",
    translate: "MENU.ADMINISTRATOR.ADMIN_ADMINISTRATOR",
    type: "collapsible",
    icon: "shield",
    children: [
      {
        id: "role",
        title: "Roles",
        translate: "MENU.ADMINISTRATOR.ROLE",
        type: "item",
        icon: "circle",
        url: "pages/administrator-management/role",
      },
      {
        id: "admin",
        title: "Admins",
        translate: "MENU.ADMINISTRATOR.ADMIN",
        type: "item",
        icon: "circle",
        url: "pages/administrator-management/admin",
      },
    ],
  },
  {
    id: "e-library",
    title: "E-Libraries",
    translate: "MENU.E_LIBRARY.E_LIBRARY_MANAGEMENT",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "e-library",
        title: "List",
        translate: "MENU.E_LIBRARY.E_LIBRARY",
        type: "item",
        icon: "circle",
        url: "pages/e-library-management/e-library",
      },
      {
        id: "top-e-library",
        title: "Top E-Library List",
        translate: "MENU.E_LIBRARY.TOP_ELIBRARY_LIST",
        type: "item",
        icon: "circle",
        url: "pages/e-library-management/top-e-library",
      },
      {
        id: "e-library-category",
        title: "Categories",
        translate: "MENU.E_LIBRARY.E_LIBRARY_CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/e-library-management/e-library-category",
      },
    ],
  },
  {
    id: "setting",
    title: "Settings",
    translate: "MENU.SETTING.SETTING",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "about",
        title: "About",
        translate: "MENU.SETTING.ABOUT",
        type: "item",
        icon: "circle",
        url: "pages/settings/about",
      },
      {
        id: "about",
        title: "What We Do",
        translate: "MENU.SETTING.WHAT_WE_DO",
        type: "item",
        icon: "circle",
        url: "pages/settings/what-we-do",
      },
      {
        id: "guidelines-on-self-drive",
        title: "Guidelines on self drive",
        translate: "MENU.SETTING.GUIDELINES_ON_SELF_DRIVE",
        type: "item",
        icon: "circle",
        url: "pages/settings/guidelines-on-self-drive",
      },
    ],
  },
];
