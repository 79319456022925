// Angular Modules
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// RxJs
import { Observable } from "rxjs";
import { LocalService } from "./local.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiHttpService {
  constructor(
    // Angular Modules
    private http: HttpClient,
    private _local: LocalService
  ) {}

  public get = (url: string, options?: any): Observable<any> =>
    this.http.get(`${environment.baseUrl}${url}`, options);

  public post = (url: string, data: any, options?: any): Observable<any> => {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this._local.getData("_t")}`,
    });

    return this.http.post(`${environment.baseUrl}${url}`, data, { headers });
  };

  public put = (url: string, data: any, options?: any): Observable<any> =>
    this.http.put(`${environment.baseUrl}${url}`, data, options);

  public delete = (url: string, options?: any): Observable<any> =>
    this.http.delete(`${environment.baseUrl}${url}`, options);
}
