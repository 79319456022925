<div class="row d-flex align-items-center border-top pt-1 pb-1 ml-0 mr-0">
  <div class="col-6 d-flex align-items-center">
    <select
      [(ngModel)]="store.limit"
      (ngModelChange)="changePaginate()"
      class="form-control mx-25 col-2"
    >
      <option [value]="25" selected>25</option>
      <option [value]="50">50</option>
      <option [value]="100">100</option>
    </select>
    <span> entries of 100 items </span>
  </div>
  <div
    class="col-md-6 col-sm-12 d-flex justify-content-md-end align-items-center"
  >
    <ngb-pagination
      [(page)]="store.current_page"
      [pageSize]="store.limit"
      [collectionSize]="store.total_items"
      [rotate]="true"
      [boundaryLinks]="true"
      [ellipses]="true"
      [maxSize]="5"
      (pageChange)="changePaginate()"
      #pagination
    >
      <ng-template ngbPaginationFirst>First</ng-template>
      <ng-template ngbPaginationPrevious
        ><span
          [data-feather]="'chevron-left'"
          [class]="'font-weight-bolder'"
        ></span
        >Prev
      </ng-template>
      <ng-template ngbPaginationNext
        >Next<span
          [data-feather]="'chevron-right'"
          [class]="'font-weight-bolder'"
        ></span>
      </ng-template>
      <ng-template ngbPaginationLast>Last</ng-template>
    </ngb-pagination>
  </div>
</div>
