import { NgModule } from "@angular/core";
import { CoreSpinnerComponent } from "./core-spinner.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [CoreSpinnerComponent],
  exports: [CoreSpinnerComponent],
  imports: [MatProgressSpinnerModule, CommonModule],
})
export class CoreSpinnerModule {}
