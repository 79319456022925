import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "appMimeType",
  pure: true,
})
export class MimeTypePipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}
  transform(file: any) {
    switch (file.mimeType) {
      case "application/pdf":
        return "./assets/images/icons/pdf-svgrepo-com.svg";
      case "text/csv":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "./assets/images/icons/xls-svgrepo-com.svg";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "./assets/images/icons/doc-svgrepo-com.svg";
      default: {
        return file.url;
      }
    }
  }
}
