import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-paginate",
  templateUrl: "./paginate.component.html",
  styleUrls: ["./paginate.component.scss"],
})
export class PaginateComponent implements OnInit {
  @Input() store;
  @Input() function;
  constructor() {}

  ngOnInit() {}

  changePaginate() {
    if (this.function) {
      this.store[this.function]();
    } else {
      this.store.list();
    }
  }
}
