import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "titleCaseWord",
})
export class TitleCaseWordPipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) return value;
    return value[0].toUpperCase() + value.substr(1).toLowerCase();
  }
}
